"use client";
import dynamic from "next/dynamic";

const CookiePreferencesModal = dynamic(
  () => import("./cookie-preferences-modal"),
  {
    ssr: false,
  }
);

export default function CookiePreferencesPopup() {
  return <CookiePreferencesModal />;
}
