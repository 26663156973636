"use client";

import { useCallback } from "react";
import dynamic from "next/dynamic";
import { useRouter, useSearchParams } from "next/navigation";
import { SHOW_TERMS_OF_SALE_MODAL_QUERY_PARAM } from "./constants";

// Client Components:
const TermsOfSaleModalHomePage = dynamic(
  () => import("../../../components/common/terms-of-sale-modal/CgvModal")
);

interface TermsOfSaleModalProps {
  messages: {
    buttonMessage: string;
    ariaMessage: string;
  };
}

export default function TermsOfSaleModal({ messages }: TermsOfSaleModalProps) {
  const { buttonMessage, ariaMessage } = messages;
  const router = useRouter();

  const showTermsOfSaleModal = useSearchParams()?.get(
    SHOW_TERMS_OF_SALE_MODAL_QUERY_PARAM
  );

  const handleModalClose = useCallback(() => {
    router.replace("/");
  }, [router]);

  return (
    <>
      {showTermsOfSaleModal === "true" && (
        <TermsOfSaleModalHomePage
          showTermsOfSaleModal={true}
          buttonMessage={buttonMessage}
          ariaMessage={ariaMessage}
          onModalClose={handleModalClose}
        />
      )}
    </>
  );
}
