import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/app/app/(root)/(news)/cookie-preferences-popup.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/app/app/(root)/(news)/private-policy-modal.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/app/app/(root)/(news)/terms-of-sale-modal.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["BumpoutModal"] */ "/opt/app/components/common/BumpoutModal.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CookiePolicyModal"] */ "/opt/app/components/common/footer/CookiePolicyModal.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CookiePreferencesLink"] */ "/opt/app/components/common/footer/CookiePreferences.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LegalNoticeModal"] */ "/opt/app/components/common/footer/LegalNoticeModal.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PrivatePolicyModalLink"] */ "/opt/app/components/common/private-policy-modal/PrivatePolicyModalLink.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/app/components/common/terms-of-sale-modal/CgvModal.tsx");
;
import(/* webpackMode: "eager" */ "/opt/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/opt/app/node_modules/next/dist/client/link.js");
