"use client";

import { useCallback } from "react";
import dynamic from "next/dynamic";
import { useRouter, useSearchParams } from "next/navigation";
import { SHOW_PRIVACY_MODAL_QUERY_PARAM } from "./constants";

// Client Components:
const PrivatePolicyModalHomePage = dynamic(
  () =>
    import(
      "../../../components/common/private-policy-modal/PrivatePolicyModalHomePage"
    )
);

interface PrivatePolicyModalProps {
  messages: {
    buttonMessage: string;
    ariaMessage?: string;
  };
  privatePolicyURL: string;
}

export default function PrivatePolicyModal({
  messages,
  privatePolicyURL,
}: PrivatePolicyModalProps) {
  const { buttonMessage, ariaMessage } = messages;
  const router = useRouter();

  const showPrivatePolicyModal = useSearchParams()?.get(
    SHOW_PRIVACY_MODAL_QUERY_PARAM
  );

  const handleModalClose = useCallback(() => {
    router.replace("/");
  }, [router]);

  return (
    <>
      {!!showPrivatePolicyModal && (
        <PrivatePolicyModalHomePage
          buttonMessage={buttonMessage}
          ariaMessage={ariaMessage}
          privatePolicyURL={privatePolicyURL}
          onModalClose={handleModalClose}
        />
      )}
    </>
  );
}
